<template>
  <div>
    <div class="mb-1 d-flex justify-content-end">
      <b-button
        class="mt-2"
        variant="outline-danger"
        @click="$emit('onCancelClick')"
      >Close</b-button>
    </div>
    <SubscriptionInputs
      :history="history"
      :account-type="accountType"
      :coupons="coupons"
    />
    <!-- @update-data="updateSubscriptionID" -->
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import SubscriptionInputs from './SubscriptionInputs.vue'

export default {
  components: {
    SubscriptionInputs,
    BButton,
  },
  props: {
    history: Array,
    accountType: String,
    coupons: Array,
  },
  data() {
    return {
      selectedPlanID: '',
    }
  },
  methods: {
    confirmCancelPlan() {
      if (this.getClient.memberType === 'superuser') {
        // this.$route.params.id
        if (this.$route.params.id) {
          this.$store.dispatch('superadmin/cancelSubscription', { id: this.$route.params.id })
            .then(response => {
              console.log(response)
            })
        } else {
          console.log('no account id')
        }
      } else {
        this.$store.dispatch('billings/cancelSubscription')
          .then(response => {
            console.log(response)
          })
      }
    },
    updateSubscriptionID(data) {
      this.selectedPlanID = data
    },
  },
}
</script>

<style>

</style>
