<template>
  <div class="page">
    <div
      v-if="actionMsg !== null"
      class="row mb-1"
    >
      <div class="col-12">
        <b-alert
          class="p-2"
          show
          variant="warning"
        >
          <feather-icon
            class="mr-1"
            icon="AlertOctagonIcon"
          />
          {{ actionMsg }}
        </b-alert>
      </div>
    </div>
    <div
      v-if="!getShowPaymentMethodList"
      class="row"
    >

      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card
          title="Subscription Plan"
          style="max-height: 500px; overflow: auto;"
        >
          <div v-if="!isLoading">
            <SubscriptionView
              v-if="!showSubscriptionUpdate"
              :history="subscriptionHistory"
              :option="subscriptionOption"
              @onUpdateClick="showSubscriptionUpdate = true"
            />
            <SubscriptionForm
              v-if="showSubscriptionUpdate"
              :history="subscriptionHistory"
              :account-type="getClient.memberType"
              @onCancelClick="showSubscriptionUpdate = false"
            />
          </div>
          <div
            v-else
            class="text-center"
          >
            <b-spinner
              label="Loading..."
            />
          </div>
        </b-card>

        <b-card :title="isLoading ? 'Billing Information' : ''">
          <div v-if="!isLoading">
            <BillingView
              :payment-method="paymentMethodList[0]"
              @onListClick="showPaymentMethodList = true"
              @onUpdateClick="showBilliingUpdate = true"
            />
          </div>

          <div
            v-else
            class="text-center"
          >
            <b-spinner
              label="Loading..."
            />
          </div>
        </b-card>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <b-card>
          <b-button
            variant="outline-primary"
            class="mb-2"
            @click="isStatement=true"
          >
            View Statement
          </b-button>
          <b-table
            responsive="lg"
            :per-page="perPage"
            :current-page="currentPage"
            :items="users"
            :fields="[ 'id', 'total', 'status', { key: 'date', sortable: true }, 'actions']"
          >
            <!-- 'id', -->
            <template #cell(id)="data">
              <router-link :to="'/billings/' + data.item.id">
                {{ data.value }}
              </router-link>
            </template>

            <template #cell(total)="data">
              ${{ data.value }}
            </template>
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="getVarient(data.value)"
              >
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="'/billings/' + data.item.id"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            class="d-flex justify-content-center"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </b-card>
      </div>

    </div>

    <b-modal
      v-model="isStatement"
      title="Select the date range to view statement"
      ok-title="View"
      ok-only
      centered
      @ok="handleOk"
    >
      <div
        class="flex-justify-between"
        style="align-items:center;"
      >
        <vue-monthly-picker
          v-model="statement.from"
          place-holder="From"
        />
        TO
        <vue-monthly-picker
          v-model="statement.to"
          place-holder="To"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge, BDropdown, BDropdownItem, BButton, BAlert, BPagination, BSpinner,
} from 'bootstrap-vue'
import VueMonthlyPicker from 'vue-monthly-picker'

// import BillingForm from '../components/BillingForm.vue'
import BillingView from '../components/Billing/BillingView.vue'
import SubscriptionForm from '../components/Subscription/SubscriptionForm.vue'
import SubscriptionView from '../components/Subscription/SubscriptionView.vue'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    BPagination,
    VueMonthlyPicker,
    BAlert,
    SubscriptionForm,
    SubscriptionView,
    // BillingForm,
    BillingView,
    BSpinner,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      statement: {
        from: null,
        to: null,
      },
      billingInfo: {
        card: {
          card_number: '',
          card_type: '',
          expired_date: '',
        },
      },
      subscriptionHistory: [],
      isStatement: false,
      users: [],
      actionMsg: null,
      showBilliingUpdate: false,
      showPaymentMethodList: false,
      showSubscriptionUpdate: false,
      subscriptionInformation: '',
      paymentMethodList: [],
      subscriptionOption: {},
      isLoading: false,
    }
  },
  computed: {
    rows() {
      return this.users.length
    },
    getShowPaymentMethodList() {
      return this.showPaymentMethodList
    },
    getClient() {
      return this.$store.state.clientInfo
    },
  },
  created() {
    this.isLoading = true

    this.$store.dispatch('billings/getBilling').then(response => {
      console.log(response)
      this.actionMsg = response.data.action
      this.users = response.data.invoice

      if (response.data.subscription !== null) {
        // eslint-disable-next-line prefer-destructuring
        this.subscriptionHistory = response.data.subscription
        this.subscriptionInformation = response.data.subscription[0].stripe_plan
      }

      if (response.data.paymentMethod !== null) {
        this.billingInfo = response.data.paymentMethod
        this.paymentMethodList[0] = response.data.paymentMethod
      }

      this.isLoading = false
    }).catch(err => {
      console.error(err)
    })

    this.getSuitablePlan()
  },
  methods: {
    handleOk() {
      this.$router.push({ name: 'Statement Detail', query: { from: this.statement.from.format('YYYY-MM'), to: this.statement.to.format('YYYY-MM') } })
    },
    getVarient(status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Paid': return 'success'
        case 'Closed': return 'danger'
        default: return 'dark'
      }
    },
    getSuitablePlan() {
      this.$store.dispatch('billings/getSuitablePlan').then(response => {
        console.log(response)
        this.subscriptionOption = response.data
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.singleMethod label, .singleMethod span{
    display: block;
}
</style>
