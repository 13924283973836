<template>
  <div class="billingView">
    <div class="w-100 d-flex justify-content-between align-items-start">
      <h4 class="card-title">
        Billing Information
      </h4>

      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template
          v-slot:button-content
        >
          <feather-icon
            icon="MoreVerticalIcon"
            size="25"
            class="text-body align-middle mr-25"
          />
        </template>
        <b-dropdown-item
          v-if="getValueFields.cardYear !== ''"
          @click="$store.commit('SET_IS_EDIT_CARD', true)"
        >
          <feather-icon
            icon="Edit2Icon"
            class="mr-50"
          />
          <span>Edit</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="getValueFields.cardYear !== ''"
          @click="$store.commit('SET_IS_DELETE_CARD', true)"
        >
          <feather-icon
            icon="Trash2Icon"
            class="mr-50"
          />
          <span>Delete</span>
        </b-dropdown-item>
        <b-dropdown-item @click="$router.push({name: 'Profile', hash: '#Billing'})">
          <feather-icon
            icon="ArchiveIcon"
            class="mr-50"
          />
          <span>Wallet</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- v-if="Object.keys(getValueFields).length" -->
    <vue-paycard
      v-if="getValueFields.cardYear !== ''"
      :value-fields="getValueFields"
      :has-random-backgrounds="false"
    />
    <!-- v-else -->
    <b-alert
      v-else
      variant="warning"
      show
    >
      <div class="alert-body">
        <feather-icon
          class="mr-1"
          icon="AlertOctagonIcon"
        />
        No Billing Payment Method
      </div>
    </b-alert>

    <EditCard :prop-payment-method="paymentMethodInfo" />
    <DeleteCard :target-card="paymentMethodInfo" />
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'

import EditCard from './EditCard.vue'
import DeleteCard from './DeleteCard.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BAlert,
    EditCard,
    DeleteCard,
  },
  props: {
    paymentMethod: Object,
  },
  data() {
    return {
      valueFields: {
        cardName: '',
        cardNumber: '0000 0000 0000 0000',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
      paymentMethodInfo: {},
    }
  },
  computed: {
    getValueFields() {
      return this.valueFields
    },
    getPaymentMethod() {
      return this.paymentMethodInfo
    },
  },
  watch: {
    paymentMethod: {
      deep: true,
      handler(newVal) {
        this.paymentMethodInfo = newVal
        this.getCardNumber()
        this.getExpMonthYear()
      },
    },
  },
  created() {
    if (this.paymentMethod) {
      this.paymentMethodInfo = this.paymentMethod
      this.getCardNumber()
      this.getExpMonthYear()
    }
  },
  methods: {
    getCardNumber() {
      if (this.paymentMethodInfo.card) {
        // const numberArr = this.paymentMethodInfo.card.card_number.split(' ')
        this.valueFields.cardNumber = this.paymentMethodInfo.card.card_number
      }
    },
    getExpMonthYear() {
      if (this.paymentMethod.card) {
        // const dateArr = this.paymentMethod.card.expired_date.split('/')
        // month
        if (this.paymentMethod.card.expired_date_month.toString().length === 1) {
          this.valueFields.cardMonth = '0' + this.paymentMethod.card.expired_date_month
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.valueFields.cardMonth = this.paymentMethod.card.expired_date_month
        }
        // year
        this.valueFields.cardYear = this.paymentMethod.card.expired_date_year
      }
    },
  },
}
</script>

<style>
  .billingView .card-item__info {
    display: none;
  }

  .billingView label{
    color: #fff !important;
  }

  .billingView .dropdown-toggle {
    padding: 0 !important;
  }

  .card-item__dateItem {
    font-size: 18px !important;
  }
</style>
