<template>
  <validation-observer
    ref="deleteCardForm"
    #default="{invalid}"
  >
    <b-modal
      id="delete-card-modal"
      v-model="getIsShowModal"
      title="Delete Card"
      centered
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      @ok="(e) => handleOk(e, invalid)"
    >
      <form
        @submit.stop.prevent="handleDeleteSubmit(invalid)"
      >

        <b-form-group
          label="Enter your Password to delete card"
          label-for="password"
          invalid-feedback="Password is required"
          :state="!invalid"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="delete-card-password"
                v-model="passwordToDelete"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="password"
              />

              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility('password')"
                />
              </b-input-group-append>
            </b-input-group>
          </validation-provider>
        </b-form-group>
      </form>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BFormGroup, BInputGroupAppend, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
  },
  mixins: [togglePasswordVisibility],
  props: {
    targetCard: Object,
  },
  data() {
    return {
      passwordToDelete: '',
    }
  },
  computed: {
    getIsShowModal: {
      get() {
        return this.$store.state.isDeleteCardModalShow
      },
      set(newVal) {
        this.$store.commit('SET_IS_DELETE_CARD', newVal)
      },
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    handleOk(e, invalid) {
      e.preventDefault()
      this.handleDeleteSubmit(invalid)
    },
    handleDeleteSubmit(invalid) {
      console.log(invalid)
      console.log(this.passwordToDelete)
    },
  },
}
</script>

<style>
</style>
