<template>
  <b-card class="subscriptionOptList py-1">
    <div>
      <h2>$ <span style="font-weight: 700;">{{ formatPrice(singlePlan.amount) }}</span> <span style="font-size: 18px;">/ {{ singlePlan.payment_type }}</span></h2>
      <h4 class="mt-1">
        {{ singlePlan.label }}
      </h4>
    </div>

    <!-- singlePlan.id !== selectedPlanID -->
    <!-- && currentPlan.status !== 'canceled' -->
    <div v-if="isShowSubscribeButton">
      <!-- v-b-modal="getSignedInClient.memberType === 'superuser' && 'trial-period-modal'" -->
      <b-button
        variant="outline-primary"
        class="mt-2"
        @click="getSignedInClient.memberType !== 'superuser' ? onUpdatePlanClick(singlePlan.id) : onSuperUserSubscribeClick(singlePlan.id)"
      >
        Subscribe
      </b-button>
    </div>
    <!-- subscriptionHistory.stripe_status === 'canceled' -->
    <div v-else-if="isShowResumeButton">
      <b-button
        variant="outline-danger"
        class="mt-2"
        @click="onResumePlanClick(singlePlan.id)"
      >
        Resume
      </b-button>
      <p
        v-if="subscriptionHistory.length"
        class="pt-1"
      >
        *Ends at: {{ new Date(subscriptionHistory[0].ends_at).toLocaleString() }}
      </p>
    </div>
    <div v-else>
      <b-button
        variant="secondary"
        class="mt-2"
        disabled
      >
        Subscribed
      </b-button>
    </div>

    <b-modal
      id="trial-period-modal"
      v-model="isModalShow"
      title="Start Subscription"
      @ok="handleOk"
    >
      <label>Trial Period (Days)</label>
      <b-form-spinbutton
        id="trial-period"
        v-model="trialPeriod"
        size="sm"
        placeholder="--"
        class="mb-2"
        min="0"
        max="90"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormSpinbutton, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BFormSpinbutton,
    BModal,
  },
  props: {
    planOption: Object,
    history: Array,
    coupons: Array,
  },
  data() {
    return {
      singlePlan: {},
      selectedPlanID: '',
      subscriptionHistory: [],
      trialPeriod: 7,
      isModalShow: false,
      componentSelectedID: '',
      couponList: [],
    }
    // stripe_status
  },
  computed: {
    getSignedInClient() {
      return this.$store.state.clientInfo
    },
    isShowSubscribeButton() {
      if (this.subscriptionHistory.length === 0) {
        return true
      }

      if (this.subscriptionHistory.length > 0) {
        const current = this.subscriptionHistory[0]

        // start
        if (current.stripe_status === 'canceled') {
          return true
        }

        // switch
        if (current.stripe_status === 'active' && current.stripe_plan !== this.singlePlan.id) {
          return true
        }

        // switch
        if (current.stripe_status === 'trialing' && current.stripe_plan !== this.singlePlan.id) {
          return true
        }
      }

      return false
    },
    isShowResumeButton() {
      if (this.subscriptionHistory.length > 0 && this.getSignedInClient.memberType === 'superuser') {
        const current = this.subscriptionHistory[0]
        const currentDate = new Date().toLocaleString()

        if (current.stripe_status === 'trialing' && current.ends_at && currentDate < new Date(current.ends_at).toLocaleString()) {
          return true
        }

        if (current.stripe_status === 'active' && current.ends_at && new Date(current.ends_at).toLocaleString() < currentDate) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    planOption: {
      deep: true,
      handler(newVal) {
        this.singlePlan = newVal
      },
    },
    history: {
      deep: true,
      handler(newVal) {
        this.subscriptionHistory = newVal
      },
    },
    coupons: {
      deep: true,
      handler(newVal) {
        this.couponList = newVal
      },
    },
  },
  created() {
    this.singlePlan = this.planOption
    this.subscriptionHistory = this.history
  },
  methods: {
    formatPrice(price) {
      if (price) {
        return (price * 0.01).toFixed(2)
      }

      return 0
    },
    userStartSubscription(id) {
      this.$store.dispatch('billings/startSubscription', { plan_id: id })
        .then(response => {
          console.log(response)
        })
    },
    userSwitchSubscription(id) {
      this.$store.dispatch('billings/switchSubscription', { plan_id: id })
        .then(response => {
          console.log(response)
        })
    },
    onUpdatePlanClick(targetID) {
      if (this.subscriptionHistory.length === 0) {
        this.userStartSubscription(targetID)
      }

      if (this.subscriptionHistory.length > 0) {
        if (this.subscriptionHistory[0].stripe_status === 'canceled') {
          this.userStartSubscription(targetID)
        } else {
          this.userSwitchSubscription(targetID)
        }
      }
    },
    onResumePlanClick() {
      if (this.$route.params.id) {
        this.$store.dispatch('superadmin/resumeSubscription', { id: this.$route.params.id })
          .then(response => {
            console.log(response)
          })
      }
    },
    onSuperUserSubscribeClick(id) {
      this.isModalShow = true
      this.componentSelectedID = id
    },
    superStartSubscription() {
      const data = {
        id: this.$route.params.id,
        plan_id: this.componentSelectedID,
        trial_period: this.trialPeriod,
      }

      this.$store.dispatch('superadmin/startSubscription', data)
        .then(response => {
          console.log(response)
        })
    },
    superSwitchSubscription() {
      const data = {
        id: this.$route.params.id,
        plan_id: this.componentSelectedID,
      }

      this.$store.dispatch('superadmin/switchSubscription', data)
        .then(response => {
          console.log(response)
        })
    },
    handleOk() {
      if (this.$route.params.id) {
        if (this.subscriptionHistory.length === 0) {
          this.superStartSubscription()
        }

        if (this.subscriptionHistory.length > 0) {
          const current = this.subscriptionHistory[0]

          if (current.stripe_status === 'canceled') {
            this.superStartSubscription()
          } else {
            this.superSwitchSubscription()
          }
        }
      }
    },
  },
}
</script>

<style>
/* @import '@core/scss/vue/libs/vue-select.scss'; */

.subscriptionOptList .card-body{
    display: flex;
    justify-content: space-between;
}
</style>
