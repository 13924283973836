<template>
  <!-- Payment Method / Billing Address Update Modal -->
  <b-modal
    v-model="getIsShowModal"
    ok-variant="outline-primary"
    cancel-variant="outline-danger"
    :ok-disabled="isShowAddressEdit"
    :cancel-disabled="isShowAddressEdit"
    title="Edit Payment Method"
    @ok="handleOk"
  >
    <div
      v-if="!isShowAddressEdit"
      class="editInfoContainer"
    >
      <b-card
        v-if="isPropsLoaded"
        title="Billing Address"
      >
        <div class="d-flex justify-content-between">
          <div v-if="getBillingAddress">
            <h5>{{ paymentMethod.billing_address.address_line_1 }} {{ paymentMethod.billing_address.address_line_2 }} {{ paymentMethod.billing_address.address_city }}</h5>
            <h5>{{ paymentMethod.billing_address.address_province }} {{ paymentMethod.billing_address.address_country }} {{ paymentMethod.billing_address.address_postal_code }}</h5>
          </div>
          <div>
            <b-button
              variant="outline-primary"
              class="addressEditBtn"
              @click="isShowAddressEdit = true"
            >
              <feather-icon
                icon="Edit2Icon"
              />
            </b-button>
          </div>
        </div>
      </b-card>
      <b-card
        v-if="isPropsLoaded"
        title="Payment Method"
      >
        <h5>Card Number</h5>
        <b-form-group v-if="paymentMethod.card.card_number">
          <b-input-group
            class="input-group-merge"
          >
            <!-- paymentMethod.card.card_number -->
            <b-form-input
              id="card-number"
              v-model="getCardNumber"
              class="form-control-merge"
              readonly
            />
          </b-input-group>
        </b-form-group>

        <h5>Expiration Date</h5>
        <div class="row">
          <v-select
            v-model="updatedExpMonth"
            class="col-6"
            :clearable="false"
            :searchable="false"
            label="title"
            transition=""
            :options="month"
          />
          <v-select
            v-model="updatedExpYear"
            class="col-6"
            :clearable="false"
            :searchable="false"
            label="title"
            :options="year"
            transition=""
          />
        </div>
      </b-card>
    </div>
    <div
      v-if="isShowAddressEdit"
      class="editAddressContainer"
    >
      <validation-observer
        ref="singleCardUpdateForm"
        #default="{invalid}"
      >
        <b-card>
          <div class="d-flex justify-content-between">
            <h4 class="card-title">
              Update Billing Address
            </h4>

            <!-- <b-button
              variant="outline-primary"
              @click="isShowAddressEdit = false"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
              />
            </b-button> -->
          </div>
          <b-form-group
            label="Billing Address Line 1"
            label-for="address-line-1"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="Billing Address Line 1"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="address_line_1"
                  v-model="paymentMethod.billing_address.address_line_1"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="billingAddress1"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Billing Address Line 2"
            label-for="address-line-2"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="Billing Address Line 2"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="address_line_2"
                  v-model="paymentMethod.billing_address.address_line_2"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="billingAddress1"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="City"
            label-for="city"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="City"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="city"
                  v-model="paymentMethod.billing_address.address_city"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Postal Code"
            label-for="postal-code"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="Postal Code"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="address_line_postal_code"
                  v-model="paymentMethod.billing_address.address_postal_code"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Province"
            label-for="province"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="Province"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="address_line_province"
                  v-model="paymentMethod.billing_address.address_province"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Country"
            label-for="country"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="Country"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="address_line_country"
                  v-model="paymentMethod.billing_address.address_country"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button
              class="mr-1"
              variant="outline-danger"
              @click="isShowAddressEdit = false"
            >
              Cancel
            </b-button>
            <b-button
              variant="outline-primary"
              :disabled="invalid"
              @click="onUpdateBillingAddressClick"
            >
              Save Address
            </b-button>
          </div>
        </b-card>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BCard, BButton, BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCard,
    vSelect,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    propPaymentMethod: Object,
  },
  // paymentMethod.billing_address.address_postal_code
  data() {
    return {
      month: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      year: [],
      paymentMethod: {
        billing_address: {
          address_line_1: '',
          address_line_2: '',
          address_city: '',
          address_province: '',
          address_postal_code: '',
          address_country: '',
        },
        card: {
          card_number: '',
        },
      },
      updatedExpMonth: '',
      updatedExpYear: '',
      isShowAddressEdit: false,
    }
  },
  computed: {
    getIsShowModal: {
      get() {
        return this.$store.state.isEditCardModalShow
      },
      set(newVal) {
        this.$store.commit('SET_IS_EDIT_CARD', newVal)
      },
    },
    isPropsLoaded() {
      if (Object.keys(this.paymentMethod).length) {
        return true
      }

      return false
    },
    getCardNumber() {
      return this.paymentMethod.card.card_number
    },
    getBillingAddress() {
      return this.paymentMethod.billing_address
    },
  },
  watch: {
    propPaymentMethod: {
      deep: true,
      handler(newVal) {
        // if (newVal.card) {
        //   this.paymentMethod.card = newVal.card
        // }

        // if (newVal.billing_address) {
        //   this.paymentMethod.billing_address = newVal.billing_address
        // }

        // this.getExpMonthYear()
        this.paymentMethod = newVal
        this.getExpMonthYear()
      },
    },
  },
  created() {
    if (Object.keys(this.propPaymentMethod).length) {
      this.paymentMethod = this.propPaymentMethod

      if (this.propPaymentMethod.card) {
        this.paymentMethod.card = this.propPaymentMethod.card
      }

      if (this.propPaymentMethod.billing_address) {
        this.paymentMethod.billing_address = this.propPaymentMethod.billing_address
      }
      this.getExpMonthYear()
    }
    this.year = this.getYears(new Date().getFullYear() + 20)
  },
  methods: {
    getYears(target) {
      let currentYear = new Date().getFullYear()
      const years = []

      while (currentYear <= target) {
        years.push(currentYear++)
      }

      return years
    },
    getExpMonthYear() {
      if (this.paymentMethod.card) {
        // const dateArr = this.paymentMethod.card.expired_date.split('/')
        // month
        if (this.paymentMethod.card.expired_date_month.toString().length === 1) {
          this.updatedExpMonth = '0' + this.paymentMethod.card.expired_date_month
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.updatedExpMonth = this.paymentMethod.card.expired_date_month
        }
        // year
        this.updatedExpYear = this.paymentMethod.card.expired_date_year
      }
    },
    onUpdateBillingAddressClick() {
      const data = {
        payment_id: this.paymentMethod.card.payment_id,
        type: 'address',
        address: {
          address_line_1: this.paymentMethod.billing_address.address_line_1,
          address_line_2: this.paymentMethod.billing_address.address_line_2,
          address_city: this.paymentMethod.billing_address.address_city,
          address_province: this.paymentMethod.billing_address.address_province,
          address_country: this.paymentMethod.billing_address.address_country,
          address_postal_code: this.paymentMethod.billing_address.address_postal_code,
        },
        expire:
          {
            month: parseInt(this.updatedExpMonth, 10),
            year: this.updatedExpYear,
          },
      }

      this.$store.dispatch('billings/updatePaymentMethod', data)
        .then(response => {
          console.log(response)

          if (response.code === 200) {
            this.showToast('Success', 'CheckCircleIcon', response.message, 'success', 'bottom-right')
          }
        })
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const data = {
        payment_id: this.paymentMethod.card.payment_id,
        type: 'expiry',
        address: {
          address_line_1: this.paymentMethod.billing_address.address_line_1,
          address_line_2: this.paymentMethod.billing_address.address_line_2,
          address_city: this.paymentMethod.billing_address.address_city,
          address_province: this.paymentMethod.billing_address.address_province,
          address_country: this.paymentMethod.billing_address.address_country,
          address_postal_code: this.paymentMethod.billing_address.address_postal_code,
        },
        expire:
          {
            month: parseInt(this.updatedExpMonth, 10),
            year: this.updatedExpYear,
          },
      }

      this.$store.dispatch('billings/updatePaymentMethod', data)
        .then(response => {
          if (response.code === 200) {
            this.showToast('Success', 'CheckCircleIcon', response.message, 'success', 'bottom-right')

            this.$store.commit('SET_IS_EDIT_CARD', false)
          }
        })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.addressEditBtn {
    cursor: pointer;
}
</style>
