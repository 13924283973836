<template>
  <div>
    <div v-if="subscriptionHistory && subscriptionHistory.length">
      <b-tabs>
        <b-tab
          title="Current Plan"
          active
        >
          <div v-if="Object.keys(currentPlan).length">
            <b-card
              v-for="(item, index) in currentPlan.items.data"
              :key="'singleitem ' + index"
            >
              <b-badge
                v-if="item.plan.active"
                pill
                variant="success"
                class="text-capitalize mb-1"
              >
                Active
              </b-badge>
              <div>
                <h6>{{ item.plan.metadata.label }}</h6>
                <h6>${{ (item.plan.amount * 0.01).toFixed(2) }} / {{ item.plan.interval }}</h6>
              </div>
            </b-card>
            <b-card v-if="currentPlan.discount">
              <h6 class="m-0">
                *{{ currentPlan.discount.coupon.name }}
              </h6>
            </b-card>
          </div>
          <!-- <b-card>
            <b-badge
              pill
              :variant="getStatusVariant(subscriptionHistory[0].stripe_status)"
              class="text-capitalize mb-1"
            >
              {{ subscriptionHistory[0].stripe_status }}
            </b-badge>
            <div v-if="getPlanDetail[0]">
              <h6>
                {{ getPlanDetail[0].label }}
              </h6>

              <h6> ${{ formatPrice(getPlanDetail[0].amount) }} / {{ getPlanDetail[0].payment_type }} </h6>
            </div>
            <h6
              v-if="subscriptionHistory[0].ends_at"
              class="mt-1 text-capitalize"
            >
              Subscription ends at {{ formatDate(subscriptionHistory[0].ends_at) }}
            </h6>
            <h6
              v-else-if="subscriptionHistory[0].trial_ends_at && subscriptionHistory[0].stripe_status !== 'active'"
              class="mt-1 text-capitalize"
            >
              Trial ends at {{ formatDate(subscriptionHistory[0].trial_ends_at) }}
            </h6>
          </b-card> -->

          <div>
            <b-button
              v-if="isShowUpdateBtn"
              class="mt-2 mr-1"
              variant="outline-primary"
              @click="onPlanUpdateClick"
            >Update</b-button>
            <b-button
              v-if="isShowCancelBtn"
              class="mt-2"
              variant="outline-danger"
              @click="onPlanCancelClick"
            >Cancel Plan</b-button>
          </div>
        </b-tab>
        <!-- <b-tab title="History">
          <b-card
            v-for="(currnet, index) in subscriptionHistory"
            :key="'current-plan'+index"
            class="mb-1"
          >
            <b-badge
              pill
              :variant="getStatusVariant(currnet.stripe_status)"
              class="text-capitalize mb-1"
            >
              {{ currnet.stripe_status }}
            </b-badge>
            <div v-if="getPlanDetail[index]">
              <h6>
                {{ getPlanDetail[index].label }}
              </h6>

              <h6> ${{ formatPrice(getPlanDetail[index].amount) }} / {{ getPlanDetail[index].payment_type }} </h6>
            </div>
            <h6
              v-if="currnet.ends_at"
              class="mt-1 text-capitalizeUnsubscribe Plan
"
            >
              Subscription ends at {{ formatDate(currnet.ends_at) }}
            </h6>
            <h6
              v-else-if="currnet.trial_ends_at"
              class="mt-1 text-capitalize"
            >
              Trial ends at {{ formatDate(currnet.trial_ends_at) }}
            </h6>
          </b-card>
        </b-tab> -->
        <b-tabs />

        <b-modal
          v-if="subscriptionHistory.length > 0 && currentPlanDetail.length > 0"
          id="unsubscribe-plan-modal"
          ref="ref-unsubscribe-plan-modal"
          title="Cancel Plan"
          centered
          ok-variant="outline-primary"
          cancel-variant="outline-danger"
          @ok="onCancelPlanClick"
        >
          Cancel <span style="font-weight: 700;">{{ currentPlanDetail[0].label }}</span>?

          <b-form-group
            v-if="getClient.memberType === 'superuser'"
            v-slot="{ ariaDescribedby }"
            label="Time to cancel plan"
            class="mt-2"
          >
            <b-form-radio-group
              v-model="selectedTime"
              :options="timeOption"
              :aria-describedby="ariaDescribedby"
              name="plain-inline"
              plain
              stacked
            />
          </b-form-group>
        </b-modal>
      </b-tabs></div>
    <div v-else>
      <div class="mb-3">
        <b-button
          class="mr-1"
          variant="outline-primary"
          @click="$emit('onUpdateClick')"
        >Select Subscription</b-button>
      </div>
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <feather-icon
            class="mr-1"
            icon="AlertOctagonIcon"
          />
          No Subscription Information
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {
  BButton, BModal, BAlert, BTab, BTabs, BFormGroup, BFormRadioGroup, BCard, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BButton,
    BModal,
    BAlert,
    BTab,
    BTabs,
    BCard,
    BFormGroup,
    BFormRadioGroup,
  },
  props: {
    currentSubscription: {
      type: Object,
      default: () => {},
    },
    history: {
      type: Array,
      default: () => [],
    },
    option: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentPlan: {},
      subscriptionHistory: [],
      subscriptionOption: {},
      currentPlanDetail: [],
      selectedTime: 'grace',
      timeOption: [{ text: 'Grace (The account will be active until billing cycle)', value: 'grace' }, { text: 'Now', value: 'now' }],
    }
  },
  computed: {
    getPlanDetail() {
      return this.currentPlanDetail
    },
    isShowCancelBtn() {
      if (this.subscriptionHistory !== null && this.subscriptionHistory.length > 0) {
        const latest = this.subscriptionHistory[0]

        // active
        if (latest.stripe_status === 'active' && !latest.ends_at) {
          return true
        }

        // trialing
        if (latest.stripe_status === 'trialing' && latest.trial_ends_at && !latest.ends_at) {
          return true
        }

        // canceled
      }

      return false
    },
    isShowUpdateBtn() {
      if (this.subscriptionHistory.length > 0) {
        const latest = this.subscriptionHistory[0]
        const currentDate = new Date()

        if (this.getClient.memberType === 'superuser') {
          return true
        }

        // active
        if (latest.stripe_status === 'active' && !latest.ends_at) {
          return true
        }

        // trialing
        if (latest.stripe_status === 'trialing' && latest.trial_ends_at && !latest.ends_at) {
          return true
        }

        // canceled
        if (latest.stripe_status === 'canceled' && new Date(latest.ends_at) > currentDate) {
          return true
        }
      }

      return false
    },
    getClient() {
      return this.$store.state.clientInfo
    },
  },
  watch: {
    history: {
      deep: true,
      handler(newVal) {
        this.subscriptionHistory = newVal

        if (this.history.length) {
          const [item] = this.history
          this.currentPlan = item
        }
        // this.addCurrentPlanDetail()
      },
    },
    option: {
      deep: true,
      handler(newVal) {
        console.log('watch', newVal)
        this.subscriptionOption = newVal
        this.addCurrentPlanDetail()
      },
    },
  },
  created() {
    this.subscriptionHistory = this.history

    console.log(this.history)

    if (this.history.length) {
      const [item] = this.history
      this.currentPlan = item
    }
    this.subscriptionOption = this.option
    this.addCurrentPlanDetail()
  },
  methods: {
    getStatusVariant(status) {
      switch (status) {
        case 'trialing': return 'warning'
        case 'active': return 'success'
        case 'canceled': return 'danger'
        default: return 'dark'
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString('en-CA')
    },
    onPlanUpdateClick() {
      if (this.getClient.memberType === 'superuser') {
        this.$emit('onUpdateClick')
      } else {
        this.$router.push({
          name: 'Create Ticket',
          params: {
            title: 'Switch Plan',
            category: 'Account',
            subCategory: 'change_agent_email',
          },
        })
      }
    },
    onPlanCancelClick() {
      if (this.getClient.memberType === 'superuser') {
        this.$bvModal.show('unsubscribe-plan-modal')
      } else {
        this.$router.push({
          name: 'Create Ticket',
          params: {
            title: 'Cancel Plan',
            category: 'Account',
            subCategory: 'change_agent_email',
          },
        })
      }
    },
    onCancelPlanClick() {
      if (this.getClient.memberType === 'superuser') {
        if (this.$route.params.id) {
          const data = {
            id: this.$route.params.id,
            time: this.selectedTime,
          }
          this.$store.dispatch('superadmin/cancelSubscription', data)
            .then(response => {
              console.log(response)
            })
        } else {
          console.log('no account id')
        }
      } else {
        this.$store.dispatch('billings/cancelSubscription')
          .then(response => {
            console.log(response)
          })
      }
    },
    formatPrice(price) {
      if (price) {
        return (price * 0.01).toFixed(2)
      }

      return 0
    },
    addCurrentPlanDetail() {
      if (this.subscriptionOption && Object.keys(this.subscriptionOption).length) {
        Object.keys(this.subscriptionOption).forEach(type => {
          const planType = this.subscriptionOption[type]
          Object.keys(planType).forEach(opt => {
            this.subscriptionHistory.forEach(selectedPlan => {
              if (Array.isArray(planType[opt])) {
                planType[opt].forEach(singleOpt => {
                  if (singleOpt.id === selectedPlan.stripe_plan) {
                    this.currentPlanDetail.push(singleOpt)
                  }
                })
              } else if (planType[opt].id === selectedPlan.stripe_plan) {
                this.currentPlanDetail.push(planType[opt])
              }
            })
          })
        })
      }
    },
  },
}
</script>

<style>

</style>
