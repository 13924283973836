<template>
  <div class="subscriptionOptContainer">
    <b-alert
      v-if="err !== ''"
      variant="danger"
      show
    >
      <div class="alert-body">
        <feather-icon
          class="mr-1"
          icon="AlertOctagonIcon"
        />
        {{ err }}
      </div>
    </b-alert>
    <b-tabs>
      <!-- v-if="selectedPlanType !== key" -->
      <b-tab
        v-for="(type, key) in getOptions"
        :key="key + '-option'"
        :title="key"
      >
        <div>
          <div
            v-for="(level, levelKey) in type"
            :key="levelKey"
          >
            <div
              v-if="Array.isArray(level)"
              class="row"
            >
              <div
                v-for="(option, optKey) in level"
                :key="optKey"
                class="col-sm-12 col-xs-12"
                :class="getGrid(option.type)"
              >
                <!-- :class="getGrid(option.type)" -->
                <div v-if="account_type && option.type === getAccountType">
                  <!-- :id="selectedPlanID" -->
                  <SubscriptionPlanCard
                    v-if="$route.name !== 'Billings'"
                    :history="history"
                    :coupons="coupons"
                    :plan-option="option"
                  />
                  <SubscriptionPlanListCard
                    v-else
                    :id="selectedPlanID"
                    :history="history"
                    :coupons="coupons"
                    :plan-option="option"
                  />
                </div>
              </div>
            </div>
            <div
              v-else-if="account_type && level.type === getAccountType"
              class="row"
            >
              <div
                class="col-sm-12 col-xs-12"
                :class="getGrid(level.type)"
              >
                <!-- :id="selectedPlanID" -->
                <!-- :plan-option="level" -->
                <SubscriptionPlanCard
                  v-if="$route.name !== 'Billings'"
                  :history="history"
                  :plan-option="level"
                  :coupons="coupons"
                  :account-type="account_type"
                />
                <SubscriptionPlanListCard
                  v-else
                  :id="selectedPlanID"
                  :history="history"
                  :coupons="coupons"
                  :account-type="account_type"
                  :plan-option="level"
                />
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>

</template>

<script>
import {
  BTabs, BTab, BAlert,
} from 'bootstrap-vue'
import SubscriptionPlanCard from './SubscriptionPlanCard.vue'
import SubscriptionPlanListCard from './SubscriptionPlanListCard.vue'

export default {
  components: {
    SubscriptionPlanCard,
    BTabs,
    BTab,
    BAlert,
    SubscriptionPlanListCard,
  },
  props: {
    history: Array,
    accountType: String,
    coupons: Array,
  },
  data() {
    return {
      selectedPlanID: -1,
      selectedPlanType: '',
      planOptions: [],
      err: '',
      account_type: '',
      subscriptionHistory: [],
    }
  },
  computed: {
    getOptions() {
      if (this.planOptions) {
        const matched = Object.keys(this.planOptions).filter(key => {
          return key.includes(this.selectedPlanType)
        })

        if (matched.length > 0) {
          return Object.keys(this.planOptions).filter(key => {
            return matched.includes(key)
          }).reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
            obj[key] = this.planOptions[key]
            return obj
          }, {})
        }
      }

      return this.planOptions
    },
    getAccountType() {
      if (this.account_type === 'agent') {
        return 'personal'
      }

      return this.account_type
    },
  },
  watch: {
    history: {
      immediate: true,
      deep: true,
      handler(val) {
        this.subscriptionHistory = val

        if (val && val.length) {
          this.selectedPlanID = val[0].stripe_plan
        }
        this.getSelectedType()
      },
    },
    accountType: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.account_type = val.charAt(0).toLowerCase() + val.slice(1)
        }
      },
    },
  },
  created() {
    this.$store.dispatch('billings/getSuitablePlan').then(response => {
      if (response.code === 200) {
        this.planOptions = response.data
        this.getSelectedType()
      } else {
        this.err = response.message
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.selectedPlanID)
    },
    getSelectedType() {
      if (this.planOptions && Object.keys(this.planOptions).length) {
        Object.keys(this.planOptions).forEach(type => {
          const planType = this.planOptions[type]

          Object.keys(planType).forEach(opt => {
            if (Array.isArray(planType[opt])) {
              planType[opt].forEach(singleOpt => {
                if (singleOpt.id === this.selectedPlanID) {
                  this.selectedPlanType = singleOpt.payment_type
                  // eslint-disable-next-line no-useless-return
                  return
                }
              })
            } else if (planType[opt].id === this.selectedPlanID) {
              this.selectedPlanType = planType[opt].payment_type
              // eslint-disable-next-line no-useless-return
              return
            }
          })
          // })
        })
      }
    },
    getGrid(optionType) {
      if (this.account_type && this.getAccountType === optionType) {
        if (this.$route.name !== 'Billings') {
          return 'col-lg-6 col-md-6'
        }

        return 'col-lg-12 col-md-12'
      }

      return 'none'
    },
  },
}
</script>

<style>
  .custom-select,
  .subscriptionOptContainer .nav-tabs .nav-item {
    text-transform: capitalize;
  }

  .none {
    display: none;
  }
</style>
